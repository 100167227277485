import React from 'react';
import { CityContext } from '~/services/Contexts/index';

const colorConfigurationMap = {
  color_primary: [
    'color_primary',
    'color_primary_hover',
    'color_primary_active',
  ],
};

function generateStyleContent(colors) {
  const colorsArr = Object.keys(colors);
  if (!colorsArr.length) return '';

  const rootVars = colorsArr.reduce(
    (prev, next) => prev.concat(getSingleColorStyle(next, colors)),
    []
  );

  return `
    :root {
      ${rootVars.join('\n')}
    }`;
}

function getSingleColorStyle(color, colors) {
  if (colorConfigurationMap[color]) {
    return colorConfigurationMap[color]
      .map(name => {
        if (colors[name] && name !== color) {
          return null;
        }

        return createCssVar(name, colors, color);
      })
      .filter(result => result);
  }

  return [createCssVar(color, colors)];
}

function createCssVar(name, list, value) {
  return `--${name.split('_').join('-')}: ${list[value || name]};`;
}

const ThemeCssStyles = ({ cityColors }) => (
  <CityContext.Consumer>
    {cityContext => {
      const hasColorStyle =
        cityColors || (cityContext.city.style && cityContext.city.style.colors);
      return hasColorStyle ? (
        <style data-testid="city-theme-styles__var-overrides">
          {generateStyleContent(cityColors || cityContext.city.style.colors)}
        </style>
      ) : null;
    }}
  </CityContext.Consumer>
);

export default ThemeCssStyles;
